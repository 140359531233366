<template>
  <div class="logo-style block block--white block--rounded">
    <div class="block__row">
      <div class="block__col">
        <div class="block__title">
          Логотип
        </div>
        <div class="block__field">
          <esmp-upload
            action="/api/files"
            type="drag"
            auto-upload
            :on-success="onUploadLogoFile"
            :show-upload-list="false"
          >
            <span class="esmp-upload__label esmp-upload-drag">Выбрать файл</span>
          </esmp-upload>
        </div>
        <div class="block__field">
          <img
            class="logo-style__image"
            :src="logoFileUrl"
            alt="logo"
            :width="logoWidth"
            :height="logoHeight"
          >
        </div>
      </div>
      <div class="block__col">
        <div class="block__title">
          Размер
        </div>
        <div class="block__field">
          <div key="width" class="logo-style__option">
            <span class="logo-style__label">Ширина:</span>
            <div class="logo-style__range">
              <input
                class="logo-style__range-input"
                type="range"
                :min="widthOptions.min"
                :max="widthOptions.max"
                v-model="logoWidth"
              >
              <div
                class="logo-style__range-progress"
                :style="widthStyle"
              />
            </div>
            <esmp-input
              class="logo-style__input"
              :options="widthOptions"
              :value="logoWidth"
              @input="debounceWidth"
            />
          </div>
        </div>
        <div class="block__field">
          <div key="height" class="logo-style__option">
            <span class="logo-style__label">Высота:</span>
            <div class="logo-style__range">
              <input
                class="logo-style__range-input"
                type="range"
                :min="heightOptions.min"
                :max="heightOptions.max"
                v-model="logoHeight"
              >
              <div
                class="logo-style__range-progress"
                :style="heightStyle"
              />
            </div>
            <esmp-input
              class="logo-style__input"
              :options="heightOptions"
              :value="logoHeight"
              @input="debounceHeight"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('logo')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';
import Hub from '@/plugins/event-hub';

export default {
  name: 'LogoStyle',
  data() {
    return {
      widthOptions: {
        type: 'number',
        min: 20,
        max: 300,
      },
      heightOptions: {
        type: 'number',
        min: 20,
        max: 50,
      },
    };
  },
  computed: {
    ...mapState('portalStyle', ['settings']),
    logoWidth: {
      get() {
        return String(this.settings.common.logo.width);
      },
      set(newValue) {
        this.setSetting({
          path: ['common.logo', 'width'],
          value: Number(newValue),
        });
      },
    },
    logoHeight: {
      get() {
        return String(this.settings.common.logo.height);
      },
      set(newValue) {
        this.setSetting({
          path: ['common.logo', 'height'],
          value: Number(newValue),
        });
      },
    },
    widthStyle() {
      const width = (this.logoWidth - this.widthOptions.min) / (this.widthOptions.max - this.widthOptions.min);
      return {
        width: `${width * 100}%`,
      };
    },
    heightStyle() {
      const width = (this.logoHeight - this.heightOptions.min) / (this.heightOptions.max - this.heightOptions.min);
      return {
        width: `${width * 100}%`,
      };
    },
    logoFileUrl: {
      get() {
        return this.settings.common.logo.imageUrl;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.logo', 'imageUrl'],
          value: newValue,
        });
      },
    },
  },
  watch: {
    logoWidth(val) {
      if (val > this.widthOptions.max) this.logoWidth = this.widthOptions.max;
      if (val < this.widthOptions.min) this.logoWidth = this.widthOptions.min;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['logo'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки логотипа успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    setHeight(val) {
      const currentHeight = parseInt(val, 10);
      if (currentHeight > this.heightOptions.max) {
        this.logoHeight = this.heightOptions.max;
      } else if (currentHeight < this.heightOptions.min) {
        this.logoHeight = this.heightOptions.min;
      } else {
        this.logoHeight = currentHeight;
      }
    },
    setWidth(val) {
      const currentWidth = parseInt(val, 10);
      if (currentWidth > this.widthOptions.max) {
        this.logoWidth = this.widthOptions.max;
      } else if (currentWidth < this.widthOptions.min) {
        this.logoWidth = this.widthOptions.min;
      } else {
        this.logoWidth = currentWidth;
      }
    },
    async onUploadLogoFile(res) {
      this.logoFileUrl = await res?.url;
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
  created() {
    this.debounceHeight = debounce(this.setHeight, SEARCH_DELAY);
    this.debounceWidth = debounce(this.setWidth, SEARCH_DELAY);
  },
};
</script>

<style lang="scss">
.logo-style {

  &__option {
    display: flex;
    align-items: center;
  }

  &__label {
    width: 70px;
    flex-shrink: 0;
    margin-right: 16px;
    color: $color-black-op-70;
  }

  &__range {
    width: 100%;
    margin-right: 16px;
    position: relative;
  }

  &__range-progress {
    position: absolute;
    top: 12px;
    left: 0;
    background-color: $color-primary-1-day;
    height: 2px;
    width: 20px;
  }

  &__range-input {
    width: 100%;
    -webkit-appearance: none;
    height: 2px;
    background-color: $color-white-status;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: ew-resize;
      background-color: $color-primary-1-day;
      transition: box-shadow $base-animation;

      &:hover {
        box-shadow: 0 0 15px 0 rgba($color-primary-1-day, .5);
      }
    }
  }

  &__input {
    width: 70px;
    flex-shrink: 0;
  }

  &__image {
    object-fit: contain;
    object-position: left center;
  }
}
</style>
